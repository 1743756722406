.App .container {
  margin: 0 auto;
}

.App .wrapper {
  margin: 2% 2%;
}

#login-card {
  margin: 2% auto;
  max-width: 500px;
}

#where-button {
  align-items: center;
  padding: 1%;
}

.App form {
  margin: 2% 2%;
}

.App .spinner-border {
  margin: 1% 50%;
}

.App #show-advanced-filter-btn {
  background: none;
  border: none;
  color: blue;
  text-decoration: underline dotted;
}
.App #show-advanced-filter-btn:hover {
  cursor: pointer;
}
